import React from 'react';
import { IconArrowHead } from '../../components';

import css from './ListingPage.module.css';
import { findCategoryNameById } from '../../util/custom';

const SectionCategoryMaybe = props => {
  const { publicData, categoryConfiguration } = props;
  const { categories } = categoryConfiguration || {};
  const { categoryLevel1, categoryLevel2, categoryLevel3 } = publicData;
  const categoryLevel1Name = findCategoryNameById(categories, categoryLevel1);
  const categoryLevel2Name = findCategoryNameById(categories, categoryLevel2);
  const categoryLevel3Name = findCategoryNameById(categories, categoryLevel3);

  return categoryLevel1Name ? (
    <section className={css.sectionCategory}>
      {categoryLevel1Name ? <span className={css.categoryText}>{categoryLevel1Name}</span> : null}
      {categoryLevel2Name ? (
        <>
          <IconArrowHead className={css.categoryIcon} direction="right" size="small" />
          <span className={css.categoryText}>{categoryLevel2Name}</span>
        </>
      ) : null}
      {categoryLevel3Name ? (
        <>
          <IconArrowHead className={css.categoryIcon} direction="right" size="small" />
          <span className={css.categoryText}>{categoryLevel3Name}</span>
        </>
      ) : null}
    </section>
  ) : null;
};

export default SectionCategoryMaybe;
